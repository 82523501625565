import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../Firebase';
import { IoMdDownload } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';

const DownloadSignedAgreement = () => {
  const { agreementId } = useParams();
  const [signedUrl, setSignedUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSignedAgreement = async () => {
      try {
        const signedAgreementRef = ref(storage, `signed_agreements/${agreementId}.pdf`);
        const url = await getDownloadURL(signedAgreementRef);
        setSignedUrl(url);
      } catch (err) {
        setError('Error fetching signed agreement');
      }
      setLoading(false);
    };

    fetchSignedAgreement();
  }, [agreementId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white">
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-black text-white">
      <h2 className="text-4xl font-bold mb-8">Download Signed Agreement</h2>
      {signedUrl ? (
        <a
          href={signedUrl}
          download={`signed_agreement_${agreementId}.pdf`}
          className="bg-white text-black font-semibold py-3 px-6 rounded-full shadow-lg flex items-center hover:bg-gray-200 transition duration-300"
        >
          <IoMdDownload className="mr-2" size={24} />
          Download Signed Agreement
        </a>
      ) : (
        <p className="text-xl">No signed agreement available</p>
      )}
      <div className="mt-8 text-sm text-gray-500">
        <p>Powered by Klorah</p>
      </div>
    </div>
  );
};

export default DownloadSignedAgreement;
