import React, { useState, Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { View, StyleSheet, Modal } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faMagic, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { auth } from './Firebase';

const userNavigation = [
  { name: 'Your Profile', href: '/profile' },
  { name: 'Membership', href: '/subscription' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateMenu = ({ isPro }) => {
  const navigate = useNavigate();

  const navigateToCreateInvoice = () => {
    navigate('/create-invoice');
  };

  const navigateToCreateContract = () => {
    if (isPro) {
      navigate('CreateContract');
    } else {
      navigate('/create-agreement');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white dark:bg-black rounded-xl p-6 w-3/4 md:w-1/2 lg:w-1/3 shadow-lg flex flex-col items-center">
        <h2 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">Create</h2>
        <div className="flex justify-center space-x-4">
          <button
            className="group flex flex-col items-center p-6 border border-transparent shadow-sm text-base font-medium rounded-xl text-black dark:text-white bg-gray-100 dark:bg-gray-700 hover:bg-black dark:hover:bg-gray-600 justify-center"
            onClick={navigateToCreateInvoice}
          >
            <FontAwesomeIcon icon={faFileInvoice} size={20} className="text-black dark:text-white group-hover:text-white" />
            <span className="group-hover:text-white">Create Invoice</span>
          </button>
          <button
            className="group flex flex-col items-center p-6 border border-transparent shadow-sm text-base font-medium rounded-xl text-black dark:text-white bg-gray-100 dark:bg-gray-700 hover:bg-black dark:hover:bg-gray-600 justify-center"
            onClick={navigateToCreateContract}
          >
            <FontAwesomeIcon icon={faMagic} size={20} className="text-black dark:text-white group-hover:text-white" />
            <span className="group-hover:text-white">AI Agreement</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  createMenuContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 20,
    height: '100%',
    width: '100%',
  },
});

const NavigationBar = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Disclosure as="nav" className="bg-white dark:bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center p-4">
                  <img
                    className="h-8 w-auto"
                    src="/klorahLogo.png"
                    alt="Klorah"
                    onClick={() => navigate('/dashboard')}
                  />
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  <a onClick={() => navigate('/crm-dashboard')} className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-800 dark:text-gray-100 hover:border-black dark:hover:border-gray-400 hover:text-black dark:hover:text-gray-400">Tasks</a>
                  <a onClick={() => navigate('/wallet')} className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-800 dark:text-gray-100 hover:border-black dark:hover:border-gray-400 hover:text-black dark:hover:text-gray-400">Wallet</a>
                </div>
              </div>
              <div className="flex items-center">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-black dark:focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <FontAwesomeIcon icon={faUserCircle} className="h-8 w-8 text-black" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-black py-1 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(active ? 'bg-gray-100 dark:bg-gray-700' : '', 'block px-4 py-2 text-sm', item.name === 'Sign out' ? 'text-red-600' : 'text-gray-700 dark:text-gray-100')}
                              onClick={item.name === 'Sign out' ? handleSignOut : null}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>

                <button
                  type="button"
                  className="ml-4 relative inline-flex items-center gap-x-1.5 rounded-xl bg-black dark:bg-white px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-gray-700 dark:hover:bg-gray-300 focus:visible:outline focus:visible:outline-2 focus:visible:outline-offset-2 focus:visible:outline-black dark:focus:visible:outline-white"
                  onClick={() => setModalVisible(true)}
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Create
                </button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              <Disclosure.Button as="a" onClick={() => navigate('/crm-dashboard')} className="block py-2 pl-3 pr-4 text-base font-medium text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-100">Tasks</Disclosure.Button>
              <Disclosure.Button as="a" onClick={() => navigate('/wallet')} className="block py-2 pl-3 pr-4 text-base font-medium text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-100">Wallet</Disclosure.Button>
            </div>
          </Disclosure.Panel>
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <View style={styles.createMenuContent} onClick={() => setModalVisible(false)}>
              <CreateMenu />
            </View>
          </Modal>
        </>
      )}
    </Disclosure>
  );
};

export default NavigationBar;
