import React, { useState, useEffect } from 'react';
import { 
  motion, 
  useScroll, 
  useTransform, 
  useMotionValue, 
  useVelocity, 
  useSpring, 
  useAnimationFrame 
} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFileContract, faUserCircle, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTwitter, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigationBar from './NavigationBar';

const headerTexts = [
  'Simplify Your Client Billing',
  'Empower Your Freelance Career',
  'Streamline Your Workflow',
  'Join the Freelance Revolution',
];

const wrap = (min, max, v) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const ParallaxContainer = ({ children, baseVelocity = 20 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-100, 0, v)}%`);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax-container overflow-hidden whitespace-nowrap">
      <motion.div className="parallax-scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <motion.div 
      whileHover={{ scale: 1.05 }}
      className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
    >
      <FontAwesomeIcon icon={icon} size="2x" className="mb-4" />
      <h4 className="text-xl font-bold mb-2">{title}</h4>
      <p>{description}</p>
    </motion.div>
  );
};

export default function LandingPage() {
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const currentText = headerTexts[currentHeaderIndex];

    const typingInterval = setInterval(() => {
      setTypedText(currentText.slice(0, currentIndex));
      currentIndex++;

      if (currentIndex > currentText.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headerTexts.length);
        }, 2000);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [currentHeaderIndex]);

  return (
    <div className="min-h-screen bg-white dark:bg-black text-black dark:text-white">
      <div className="sticky top-0 z-50">
        <NavigationBar />
      </div>
      
      <main className="container mx-auto px-4">
        <section className="flex flex-col items-center justify-center text-center py-20" style={{ minHeight: '80vh' }}>
          <div className="h-[120px] flex items-center justify-center mb-6">
            <h1 className="text-5xl md:text-7xl font-bold">
              {typedText}
              <span className="animate-pulse">|</span>
            </h1>
          </div>
          <p className="text-2xl mb-8">Your new client billing solution powered by AI</p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="bg-green-500 text-white py-3 px-6 rounded-lg text-xl"
            onClick={() => window.location.href='/signup'}
          >
            Get Started for Free
          </motion.button>
          <div className="mt-12 w-full max-w-[1200px] mx-auto">
            <div className="relative" style={{ paddingBottom: '56.25%' }}>
              <iframe 
                src="https://www.youtube.com/embed/WJLuaGxR-6c?si=cYxdHc8cAciQIQOI&autoplay=1&mute=1&vq=hd1080" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
              ></iframe>
            </div>
          </div>
        </section>

        <ParallaxContainer>Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive</ParallaxContainer>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center">Why Choose Klorah?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard 
              icon={faFileInvoice} 
              title="Effortless Invoicing" 
              description="Create and send professional invoices in seconds."
            />
            <FeatureCard 
              icon={faFileContract} 
              title="Smart Agreements" 
              description="Generate legally-sound agreements quickly with our AI tools."
            />
            <FeatureCard 
              icon={faUserCircle} 
              title="Freelancer Profiles" 
              description="Showcase your work with a stunning profile and attract new clients."
            />
            <FeatureCard 
              icon={faBriefcase} 
              title="Bookable Services" 
              description="Allow clients to book your services directly through your profile."
            />
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center">Pricing</h2>
          <div className="flex flex-wrap justify-center items-center gap-10">
            <div className="flex flex-col bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl w-full sm:w-1/2 md:w-1/3">
              <h3 className="text-3xl font-bold mb-3 text-center">Free Plan</h3>
              <h4 className="text-xl font-medium mb-1 text-center">$0/month</h4>
              <p className="text-center text-gray-500 dark:text-gray-400 mb-6">3.3% transaction fee on invoices</p>
              <ul className="space-y-3 text-lg mb-6">
                <li>Unlimited access to Klorah community</li>
                <li>Basic AI agreements</li>
                <li>Customer support</li>
                <li>Access to new features and updates</li>
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-auto bg-black text-white dark:bg-white dark:text-black py-3 px-6 rounded-lg"
                onClick={() => window.location.href='/signup'}
              >
                Get Started Now
              </motion.button>
            </div>
            <div className="flex flex-col bg-black text-white dark:bg-black dark:text-white p-8 rounded-lg shadow-xl w-full sm:w-1/2 md:w-1/3">
              <h3 className="text-3xl font-bold mb-3 text-center">Pro Plan</h3>
              <h4 className="text-xl font-medium mb-1 text-center">$15/month</h4>
              <p className="text-center text-gray-400 dark:text-gray-400 mb-6">2.9% transaction fee on invoices</p>
              <ul className="space-y-3 text-lg mb-6">
                <li>Cutting-edge invoicing capabilities</li>
                <li>AI-generated or seamlessly imported client agreements</li>
                <li>Exclusive professional-grade tools</li>
                <li>Early access to new products and features</li>
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-auto bg-white text-black dark:bg-white dark:text-black py-3 px-6 rounded-lg"
                onClick={() => window.location.href='/signup'}
              >
                Get Started Now
              </motion.button>
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center py-20"
        >
          <h2 className="text-4xl font-bold mb-6">Join the Freelance Revolution</h2>
          <p className="text-2xl mb-8">Klorah isn't just a tool—it's a community of freelancers changing the way they work. Join us and experience financial freedom.</p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="bg-green-500 text-white py-3 px-6 rounded-lg text-xl"
            onClick={() => window.location.href='/signup'}
          >
            Sign Up for Free
          </motion.button>
        </motion.section>
      </main>

      <footer className="py-12 bg-black text-gray-400">
        <div className="container mx-auto px-8 flex flex-col items-center justify-center space-y-8">
          <img
            className="h-8 w-auto"
            src="/klorahLogo.png"
            alt="Klorah"
          />
          <nav className="flex flex-wrap justify-center items-center gap-8">
            <a href="https://www.instagram.com/klorahapp/?hl=en" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
              <span>Instagram</span>
            </a>
            <a href="https://x.com/klorahapp" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
              <FontAwesomeIcon icon={faTwitter} size="lg" />
              <span>Twitter</span>
            </a>
            <a href="https://www.linkedin.com/company/klorah/" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
              <span>LinkedIn</span>
            </a>
            <a href="https://www.tiktok.com/@klorah" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
              <FontAwesomeIcon icon={faTiktok} size="lg" />
              <span>TikTok</span>
            </a>
          </nav>
          <div className="text-center max-w-2xl">
            <h3 className="text-xl font-bold mb-2">About Klorah</h3>
            <p className="text-base">Located in the heart of New York City, Klorah is committed to creating innovative financial solutions for freelancers. Our mission is to equip you with the tools you need to thrive in the gig economy.</p>
          </div>
          <div className="text-center">
            <p>&copy; {new Date().getFullYear()} Klorah, Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}