// src/components/CreateEditBlog.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../Firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDropzone } from 'react-dropzone';
import { createApi } from 'unsplash-js';

const unsplash = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_API_KEY
});

function CreateEditBlog() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageSize, setImageSize] = useState('100%'); // Default image size
  const [date, setDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [unsplashImages, setUnsplashImages] = useState([]);

  useEffect(() => {
    if (id) {
      const fetchBlog = async () => {
        const blogDoc = doc(db, 'blogs', id);
        const blogSnap = await getDoc(blogDoc);
        if (blogSnap.exists()) {
          const data = blogSnap.data();
          setTitle(data.title);
          setContent(data.content);
          setImageUrl(data.imageUrl);
          setDate(new Date(data.date));
        } else {
          console.log('No such document!');
        }
      };

      fetchBlog();
    }
  }, [id]);

  const handleSubmit = async () => {
    const blogData = { title, content, imageUrl, imageSize, date: date.toISOString() };
    if (id) {
      const blogDoc = doc(db, 'blogs', id);
      await setDoc(blogDoc, blogData);
    } else {
      await addDoc(collection(db, 'blogs'), blogData);
    }
    navigate('/');
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await unsplash.search.getPhotos({ query: searchTerm, perPage: 10 });
      if (response.response && response.response.results) {
        setUnsplashImages(response.response.results);
      } else {
        setUnsplashImages([]);
      }
    } catch (error) {
      console.error('Error fetching images from Unsplash:', error);
      setUnsplashImages([]);
    }
  };

  const handleImageSelect = (url) => {
    setImageUrl(url);
    setUnsplashImages([]);
  };

  const handleImageRemove = () => {
    setImageUrl('');
  };

  const handleImageSizeChange = (e) => {
    setImageSize(e.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-5xl font-bold my-8 text-center">{id ? 'Edit Blog' : 'Create Blog'}</h1>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        className="border p-2 w-full mb-4 text-2xl font-serif"
      />
      {imageUrl ? (
        <div className="relative mb-4">
          <img src={imageUrl} alt="Selected" className="rounded-lg shadow-lg" style={{ width: imageSize }} />
          <button
            onClick={handleImageRemove}
            className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full m-2"
          >
            &times;
          </button>
          <input
            type="range"
            min="10"
            max="100"
            value={parseInt(imageSize, 10)}
            onChange={handleImageSizeChange}
            className="w-full mt-2"
          />
        </div>
      ) : (
        <div>
          <div {...getRootProps({ className: 'dropzone border-dashed border-2 p-4 mb-4 text-center' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop an image here, or click to select one</p>
          </div>
          <div className="mb-4">
            <form onSubmit={handleSearch}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for images"
                className="border p-2 w-full mb-4"
              />
              <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">Search</button>
            </form>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
              {unsplashImages.map((image) => (
                <img
                  key={image.id}
                  src={image.urls.small}
                  alt={image.alt_description}
                  className="cursor-pointer hover:opacity-75"
                  onClick={() => handleImageSelect(image.urls.full)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <ReactQuill value={content} onChange={setContent} className="mb-4" />
      <button
        onClick={handleSubmit}
        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700"
      >
        {id ? 'Update' : 'Create'}
      </button>
    </div>
  );
}

export default CreateEditBlog;
