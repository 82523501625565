import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { View, StyleSheet, Modal } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faMagic } from '@fortawesome/free-solid-svg-icons';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const styles = StyleSheet.create({
  createMenuContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 20,
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(10px)', // Added blur effect
  },
});

const NavigationBar = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <Disclosure as="nav" className="bg-white dark:bg-black backdrop-filter backdrop-blur-lg bg-opacity-60 dark:bg-opacity-60">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center p-4">
                  <img
                    className="h-8 w-auto"
                    src="/klorahLogo.png"
                    alt="Klorah"
                    onClick={() => navigate('/')}
                  />
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  <a href="https://blog.klorah.app" className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-800 dark:text-gray-100 hover:border-black dark:hover:border-gray-400 hover:text-black dark:hover:text-gray-400">Blog</a>
                </div>
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center gap-x-1.5 rounded-lg bg-black dark:bg-white px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-gray-700 dark:hover:bg-gray-300 focus:visible:outline focus:visible:outline-2 focus:visible:outline-offset-2 focus:visible:outline-black dark:focus:visible:outline-white"
                    onClick={() => navigate('/login')}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              <Disclosure.Button as="a" href="https://blog.klorah.app" className="block py-2 pl-3 pr-4 text-base font-medium text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-100">Blog</Disclosure.Button>
              <Disclosure.Button as="a" onClick={() => navigate('/login')} className="block py-2 pl-3 pr-4 text-base font-medium text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-100">Sign In</Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavigationBar;