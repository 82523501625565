import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { collection, addDoc, serverTimestamp, getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../NavigationBar';
import LoadingPage from '../Agreement/loadingPage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import { VerificationBanner } from '../VerificationBanner';
import DarkModeButton from '../../DarkModeButton';

export default function CreateInvoice() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [gig, setGig] = useState('');
  const [customer, setCustomer] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [price, setPrice] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [invoiceId, setInvoiceId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isProUser, setIsProUser] = useState(false);
  const firestore = getFirestore();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showProModal, setShowProModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(() => {
    const auth = getAuth();
    return auth.currentUser;
  });
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [customEmailSubject, setCustomEmailSubject] = useState('');
  const [customEmailContent, setCustomEmailContent] = useState('');
  const [invoiceTheme, setInvoiceTheme] = useState('default');
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [freelancerEmail, setFreelancerEmail] = useState('');

  const themes = [
    { id: 'default', name: 'Snow White', primaryColor: '#ffffff', secondaryColor: '#f3f4f6', textColor: '#000000' },
    { id: 'modern', name: 'Minty Fresh', primaryColor: '#e0f2f1', secondaryColor: '#b2dfdb', textColor: '#004d40' },
    { id: 'classic', name: 'Golden Sunrise', primaryColor: '#fff8e1', secondaryColor: '#ffecb3', textColor: '#ff6f00' },
    { id: 'elegant', name: 'Rose Petal', primaryColor: '#fce4ec', secondaryColor: '#f8bbd0', textColor: '#880e4f' },
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        setFreelancerEmail(user.email);  // Set the freelancer's email
        fetchUserProStatus(user.uid);
      } else {
        console.error('No user is logged in.');
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      if (invoiceId) {
        const docRef = doc(firestore, 'invoices', invoiceId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setInvoiceData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      }
    };
    fetchInvoiceData();
  }, [invoiceId]);

  useEffect(() => {
    const fetchServices = async () => {
      if (currentUser) {
        const profileRef = doc(firestore, 'profiles', currentUser.uid);
        const profileSnapshot = await getDoc(profileRef);
        if (profileSnapshot.exists()) {
          const profileData = profileSnapshot.data();
          setServices(profileData.services || []);
        }
      }
    };
    fetchServices();
  }, [currentUser]);

  useEffect(() => {
    const fetchClients = async () => {
      if (currentUser) {
        const clientsRef = collection(firestore, 'users', currentUser.uid, 'clients');
        const unsubscribe = onSnapshot(clientsRef, snapshot => {
          const clientsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCustomers(clientsData);
        });
        return () => unsubscribe();
      }
    };
    fetchClients();
  }, [currentUser]);

  useEffect(() => {
    console.log('Theme modal open:', isThemeModalOpen);
  }, [isThemeModalOpen]);

  const fetchUserProStatus = async (userId) => {
    const docRef = doc(firestore, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setIsProUser(userData.isPro || false);
    } else {
      console.error('No such document!');
    }
  };

  const fetchUserDocument = async (userId) => {
    const docRef = doc(firestore, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No such document!');
      return null;
    }
  };

  const handleCustomerSelect = (selectedClient) => {
    setSelectedCustomer(selectedClient);
    setCustomer(selectedClient.name);
    setUserEmail(selectedClient.email);
    setCustomEmailSubject(`Invoice for ${selectedClient.name}`);
    setCustomEmailContent(`Dear ${selectedClient.name},\n\nPlease find attached the invoice for the gig "${gig}".\n\nInvoice Details:\nGig: ${gig}\nAmount: ${currency} ${price}\nDue Date: ${dueDate.toLocaleDateString()}\n\nThank you,\nYour Company`);
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    mixpanel.track("Invoice Generation Initiated");

    if (currentUser && currentUser.uid) {
      const userDoc = await fetchUserDocument(currentUser.uid);
      if (userDoc && userDoc.stripeAccountId) {
        if (currentUser.emailVerified) {
          const formattedDueDate = dueDate.toISOString().split('T')[0];
          try {
            const invoiceData = {
              gig,
              customer,
              price: parseFloat(price) || 0,
              currency,
              due_date: formattedDueDate,
              userId: currentUser.uid,
              freelancerEmail: freelancerEmail,  // Add this line
              created: serverTimestamp(),
              connectedAccountId: userDoc.stripeAccountId,
              theme: invoiceTheme,
            };

            const docRef = await addDoc(collection(firestore, 'invoices'), invoiceData);
            console.log("Document written with ID:", docRef.id);
            mixpanel.track("Invoice Document Created", { "Document ID": docRef.id });

            const paymentLink = `https://klorah.app/payment/${docRef.id}`;
            const updatedInvoiceData = { ...invoiceData, payment_link: paymentLink };

            if (userEmail) {
              console.log("Sending invoice email with data:", {
                to: userEmail,
                invoice: { id: docRef.id, ...updatedInvoiceData },
                customer_name: customer,
                customEmailSubject,
                customEmailContent: customEmailContent.replace(/\n/g, '<br>'),
              });

              const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/send-invoice', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  to: userEmail,
                  invoice: { id: docRef.id, ...updatedInvoiceData },
                  customer_name: customer,
                  customEmailSubject,
                  customEmailContent: customEmailContent.replace(/\n/g, '<br>'),
                }),
              });

              const result = await response.json();
              if (!response.ok) {
                throw new Error(result.detail || "Failed to send invoice email");
              }
            }

            navigate(`/preview-invoice/${docRef.id}`);
          } catch (error) {
            console.error("Error adding document:", error);
            mixpanel.track("Invoice Document Creation Error", { "Error": error });
          } finally {
            setIsLoading(false);
          }
        } else {
          console.error('User email is not verified.');
          mixpanel.track("Invoice Generation Error", { "Error": 'User email is not verified.' });
          setIsLoading(false);
          setError('You must verify your email to create invoices.');
        }
      } else {
        console.error('User does not have a connected Stripe account.');
        mixpanel.track("Invoice Generation Error", { "Error": 'User does not have a connected Stripe account.' });
        setIsLoading(false);
        setError('You must have a connected Stripe account to create invoices.');
      }
    } else {
      console.error('No user is logged in.');
      mixpanel.track("Invoice Generation Error", { "Error": 'No user is logged in.' });
      setIsLoading(false);
      setError('You must be logged in to create invoices.');
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value ? parseFloat(value) : '');
  };

  const calculateFee = () => {
    const feePercentage = isProUser ? 0.029 : 0.033;
    return parseFloat(price) * feePercentage;
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setGig(service.name);
    setPrice(service.price);
    setCurrency(service.currency);
  };

  const handleDateChange = (event, selectedDate) => {
    setShowDatePicker(Platform.OS === 'ios');
    if (selectedDate) {
      setSelectedDate(selectedDate);
    }
  };

  const InvoicePreview = () => {
    const fee = calculateFee();
    const selectedTheme = themes.find(theme => theme.id === invoiceTheme);

    return (
      <div className="mt-10 lg:mt-0 pb-4 text-black dark:text-white">
        <h2 className="text-2xl font-semibold mb-4">Invoice Preview</h2>
        <div 
          style={{
            backgroundColor: selectedTheme.primaryColor,
            color: selectedTheme.textColor,
            border: `2px solid ${selectedTheme.secondaryColor}`,
            borderRadius: '8px',
            padding: '20px',
          }}
        >
          <h3 style={{ borderBottom: `2px solid ${selectedTheme.secondaryColor}`, paddingBottom: '10px', marginBottom: '15px' }}>Invoice</h3>
          <p><strong>Gig:</strong> {gig}</p>
          <p><strong>Customer:</strong> {customer}</p>
          <p><strong>Amount:</strong> {currency} {typeof price === 'number' ? price.toFixed(2) : '0.00'}</p>
          <p><strong>Due Date:</strong> {dueDate.toLocaleDateString()}</p>
          <p><strong>Estimated Fee:</strong> {currency} {fee.toFixed(2)}</p>
        </div>
        <button
          type="button"
          onClick={() => {
            console.log('Opening theme modal');
            setIsThemeModalOpen(true);
          }}
          className="mt-4 w-full text-black py-2 rounded-md hover:underline transition duration-300 dark:text-white dark:bg-transparent"
        >
          Change Invoice Theme
        </button>
      </div>
    );
  };

  const ThemeModal = () => (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${isThemeModalOpen ? '' : 'hidden'}`}>
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4">Select Invoice Theme</h2>
        <div className="grid grid-cols-2 gap-4">
          {themes.map((theme) => (
            <div
              key={theme.id}
              className={`cursor-pointer border rounded-lg p-4 ${
                invoiceTheme === theme.id ? 'border-blue-500' : 'border-gray-200'
              }`}
              onClick={() => {
                setInvoiceTheme(theme.id);
                setIsThemeModalOpen(false);
              }}
              style={{
                backgroundColor: theme.primaryColor,
                color: theme.textColor,
              }}
            >
              <h3 className="font-bold mb-2">{theme.name}</h3>
              <div style={{ backgroundColor: theme.secondaryColor, height: '20px', marginBottom: '10px' }}></div>
              <p>Sample text</p>
            </div>
          ))}
        </div>
        <button
          onClick={() => setIsThemeModalOpen(false)}
          className="mt-6 w-full bg-gray-300 text-black py-2 rounded-md hover:bg-gray-400 transition duration-300 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
        >
          Close
        </button>
      </div>
    </div>
  );

  const ProModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Upgrade to Pro</h2>
        <p className="mb-4">Enjoy lower transaction fees and more features with our Pro plan:</p>
        <ul className="list-disc list-inside mb-4">
          <li>2.9% transaction fee instead of 3.3%</li>
          <li>Custom email templates</li>
          <li>Advanced reporting</li>
        </ul>
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate('/subscription');
          }}
          className="w-full bg-black text-white dark:bg-white dark:text-black py-2 rounded-md hover:bg-gray-800 dark:hover:bg-gray-200"
        >
          Upgrade to Pro
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            setShowProModal(false);
          }}
          className="w-full mt-2 bg-gray-200 text-black dark:bg-gray-600 dark:text-white py-2 rounded-md hover:bg-gray-300 dark:hover:bg-gray-700"
        >
          Close
        </button>
      </div>
    </div>
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  const currencies = ['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD'];

  if (!currentUser || !currentUser.emailVerified) {
    return (
      <div className="text-black dark:text-white min-h-screen">
        <NavigationBar />
        <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-lg text-red-600">You must verify your email to create invoices.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      <NavigationBar />
      <DarkModeButton />
      <div className="mx-auto max-w-7xl px-4 pb-24 pt-16 sm:px-6 lg:px-8">
        <form 
          className="lg:grid lg:grid-cols-2 lg:gap-x-14 xl:gap-x-16"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent form submission
            handleGenerate();
          }}
        >
          <InvoicePreview />

          <div>
            <div>
              <label htmlFor="gig" className="block text-sm font-medium">Gig</label>
              <input
                type="text"
                id="gig"
                value={gig}
                onChange={(e) => setGig(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
              {services.length > 0 && (
                <ul className="mt-2 divide-y divide-gray-200 border border-gray-200 rounded-md dark:divide-gray-700 dark:border-gray-700">
                  {services.map((service) => (
                    <li
                      key={service.name}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-600"
                      onClick={() => handleServiceSelect(service)}
                    >
                      {service.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="customer" className="block text-sm font-medium">Customer</label>
              <input
                type="text"
                id="customer"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
            {customers.length > 0 && (
              <ul className="mt-2 divide-y divide-gray-200 border border-gray-200 rounded-md dark:divide-gray-700 dark:border-gray-700">
                {customers.map((client) => (
                  <li
                    key={client.id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-600"
                    onClick={() => handleCustomerSelect(client)}
                  >
                    {client.name}
                  </li>
                ))}
              </ul>
            )}

            <div className="mt-4">
              <label htmlFor="email" className="block text-sm font-medium">Customer Email (Optional)</label>
              <input
                type="email"
                id="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>

            {userEmail && (
              <>
                {isProUser && (
                  <>
                    <div className="mt-4">
                      <label htmlFor="customEmailSubject" className="block text-sm font-medium">Custom Email Subject</label>
                      <input
                        type="text"
                        id="customEmailSubject"
                        value={customEmailSubject}
                        onChange={(e) => setCustomEmailSubject(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
                      />
                    </div>

                    <div className="mt-4">
                      <label htmlFor="customEmailContent" className="block text-sm font-medium">Custom Email Content</label>
                      <textarea
                        id="customEmailContent"
                        value={customEmailContent}
                        onChange={(e) => setCustomEmailContent(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
                        rows="4"
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {selectedService ? (
              <>
                <div className="mt-4">
                  <label htmlFor="price" className="block text-sm font-medium">Price</label>
                  <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={handlePriceChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>

                <div className="mt-4">
                  <label htmlFor="currency" className="block text-sm font-medium">Currency</label>
                  <select
                    id="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  >
                    {currencies.map((currency) => (
                      <option key={currency} value={currency}>{currency}</option>
                    ))}
                  </select>
                </div>
              </>
            ) : (
              <>
                <div className="mt-4">
                  <label htmlFor="currency" className="block text-sm font-medium">Currency</label>
                  <select
                    id="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  >
                    {currencies.map((currency) => (
                      <option key={currency} value={currency}>{currency}</option>
                    ))}
                  </select>
                </div>

                <div className="mt-4">
                  <label htmlFor="price" className="block text-sm font-medium">Price</label>
                  <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={handlePriceChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>
              </>
            )}

            <div className="mt-4">
              <label htmlFor="dueDate" className="block text-sm font-medium">Due Date</label>
              <DatePicker
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>

            {error && <div className="text-red-500 font-bold">{error}</div>}

            <button
              onClick={handleGenerate}
              disabled={!currentUser || !currentUser.emailVerified}
              className="mt-4 w-full rounded-md border border-transparent bg-black px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-gray-300"
            >
              Generate Invoice
            </button>

            <div className="mt-4 text-sm text-gray-600 dark:text-gray-300 text-center">
              <p>Please note: Invoices are subject to a {isProUser ? '2.9%' : '3.3%'} processing fee.</p>
              {!isProUser && (
                <p className="mt-2">
                  <button
                    onClick={() => setShowProModal(true)}
                    className="text-green-600 dark:text-green-400 hover:underline"
                  >
                    Learn about Pro fees
                  </button>
                </p>
              )}
            </div>

            {invoiceId && (
              <button
                onClick={() => navigate(`/payment/${invoiceId}`)}
                className="mt-4 w-full rounded-md border border-transparent bg-black px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-gray-300"
              >
                Pay Invoice
              </button>
            )}
          </div>
        </form>
      </div>
      <VerificationBanner />
      {showProModal && <ProModal />}
      <ThemeModal />
    </div>
  );
}
