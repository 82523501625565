import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import app, { db } from '../Firebase';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(true);
    
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const handleLoginWithEmail = async (event) => {
        event.preventDefault();

        if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
        } else {
            localStorage.removeItem('rememberMe');
        }

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            handleAuthErrors(error);
        }
    };

    // Function to handle Google Sign-In
    const handleGoogleSignIn = async () => {
        try {
            // Sign in with Google using Firebase Authentication
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const userRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(userRef);

            if (!docSnap.exists()) {
                // If the user does not exist in the database, create a new user document
                await setDoc(userRef, {
                    userId: user.uid,
                    username: user.displayName || 'Anonymous',
                    email: user.email,
                    isPro: false,
                    invoicesCreated: 0,
                    agreementsCreated: 0,
                    stripeOnboarded: false,
                });
                // Navigate to onboarding questions for new users
                navigate('/onboarding-questions');
            } else {
                // If the user already exists, handle remember me functionality
                if (rememberMe) {
                    localStorage.setItem('rememberMe', 'true');
                } else {
                    localStorage.removeItem('rememberMe');
                }
                // Navigate to the dashboard for existing users
                navigate('/dashboard');
            }
        } catch (error) {
            // Handle errors during Google Sign-In
            const errorMessage = 'Failed to sign in with Google. Please try again later.';
            setErrorMessage(errorMessage);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') setEmail(value);
        if (name === 'password') setPassword(value);
    };

    function handleAuthErrors(error) {
        let errorMessage = '';
        if (error.code === 'auth/user-not-found') {
            errorMessage = 'Invalid email address.';
        } else if (error.code === 'auth/wrong-password') {
            errorMessage = 'Invalid password.';
        } else {
            errorMessage = 'Failed to sign in. Please try again later.';
        }
        setErrorMessage(errorMessage);
    }

    return (
        <div className="flex min-h-screen bg-white dark:bg-black">
            <div className="flex flex-col justify-start px-4 py-12 sm:px-6 lg:px-20 xl:px-24 w-full max-w-md mx-auto mt-50">
                <div className="text-center">
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Welcome to Klorah</h1>
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">Your ultimate invoicing solution</p>
                </div>
                <div className="mt-6 text-center">
                    <p className="text-sm text-gray-600 dark:text-gray-400">New to Klorah? <Link to="/signup" className="text-blue-600 dark:text-blue-400 hover:underline">Create a free account</Link></p>
                </div>
                <div className="mt-8">
                    <form onSubmit={handleLoginWithEmail} className="space-y-6">
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="mt-1 p-2 w-full border border-gray-300 rounded-md dark:bg-gray-900 dark:text-white dark:border-gray-800"
                                value={email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Password</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                className="mt-1 p-2 w-full border border-gray-300 rounded-md dark:bg-gray-900 dark:text-white dark:border-gray-800"
                                value={password}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="rememberMe"
                                    name="rememberMe"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded dark:bg-gray-800 dark:border-gray-700"
                                />
                                <label htmlFor="rememberMe" className="ml-2 text-sm text-gray-700 dark:text-gray-300">Remember me</label>
                            </div>
                            <Link to="/auth/forgot-password" className="text-sm text-blue-600 dark:text-blue-400 hover:underline">Forgot password?</Link>
                        </div>
                        <button type="submit" className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-white dark:hover:bg-white dark:text-black">
                            Sign in
                        </button>
                        <div className="mt-4">
                            <button type="button" onClick={handleGoogleSignIn} className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm flex items-center justify-center text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:bg-gray-800 dark:text-white dark:border-gray-700">
                                <img src="/googleIcon.svg" alt="Google Logo" className="h-6 w-6 mr-2" />
                                Sign in with Google
                            </button>
                        </div>
                        <div className="mt-4 flex justify-between">
                            <button type="button" onClick={() => navigate('/tos')} className="text-black dark:text-white hover:underline focus:outline-none">
                                Terms of Service
                            </button>
                            <button type="button" onClick={() => navigate('/privacy-policy')} className="text-black dark:text-white hover:underline focus:outline-none">
                                Privacy Policy
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden lg:block flex-1">
                <img
                    className="h-full w-full object-cover"
                    src="./freelancer.jpg"
                    alt="Background"
                />
            </div>
        </div>
    );
}
