import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { IoIosArrowBack, IoMdRefresh, IoMdCheckmarkCircleOutline, IoMdClose } from 'react-icons/io';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../Firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';

const SigningInstructions = () => (
  <div className="mb-8">
    <h2 className="font-bold text-lg mb-5">Klorah Sign</h2>
    <ol className="list-decimal pl-4 font-bold">
      <li>Draw your signature in the signature pad below.</li>
      <li>Position your signature on the agreement preview.</li>
      <li>Click "Sign Agreement" to complete the signing process.</li>
    </ol>
  </div>
);

const SignaturePage = () => {
  const { agreementId } = useParams();
  const navigate = useNavigate();
  const [agreement, setAgreement] = useState(null);
  const [freelancerSignatures, setFreelancerSignatures] = useState([]);
  const [clientSignatures, setClientSignatures] = useState([]);
  const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 });
  const [signatureSize, setSignatureSize] = useState({ width: 100, height: 50 });
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [signingAs, setSigningAs] = useState('freelancer');

  const sigCanvas = useRef({});
  const agreementRef = useRef(null);
  const signatureRef = useRef(null);
  const containerRef = useRef(null);

  const [showSignFlow, setShowSignFlow] = useState(false);

  const toggleSignFlow = () => {
    setShowSignFlow(!showSignFlow);
  };

  useEffect(() => {
    const fetchAgreement = async () => {
      setLoading(true);
      const docRef = doc(db, 'agreements', agreementId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAgreement(docSnap.data());
        setLoading(false);
      } else {
        setError('No such document!');
        setLoading(false);
      }
    };

    const checkAuth = () => {
      const auth = getAuth();
      const isUserAuthenticated = !!auth.currentUser;
      setIsAuthenticated(isUserAuthenticated);
      setSigningAs(isUserAuthenticated ? 'freelancer' : 'client');
    };

    fetchAgreement();
    checkAuth();
  }, [agreementId]);

  const handleSignatureEnd = () => {
    const sigData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    if (signingAs === 'freelancer') {
      setFreelancerSignatures([...freelancerSignatures, sigData]);
    } else {
      setClientSignatures([...clientSignatures, sigData]);
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const handleMouseDown = (e) => {
    if (signatureRef.current && signatureRef.current.contains(e.target)) {
      setIsDragging(true);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const rect = agreementRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left - signatureRef.current.offsetWidth / 2;
      const y = e.clientY - rect.top - signatureRef.current.offsetHeight / 2;
      setSignaturePosition({ x, y });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleResize = (e) => {
    const { name, value } = e.target;
    setSignatureSize((prevSize) => ({
      ...prevSize,
      [name]: value,
    }));
  };

const signDocument = async () => {
  setLoading(true);
  try {
    // Ensure there is at least one signature and the agreement reference is available
    if ((freelancerSignatures.length > 0 || clientSignatures.length > 0) && agreementRef.current) {
      // Convert the agreement to a canvas
      const canvas = await html2canvas(agreementRef.current, {
        scale: 2, // Adjust scale for higher resolution
        useCORS: true,
        logging: true,
      });

      // Convert the canvas to an image
      const imgData = canvas.toDataURL('image/png');

      // Create a PDF and add the image
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [canvas.width, canvas.height], // Match the canvas size to avoid stretching
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      // Convert the PDF to a blob
      const pdfBlob = pdf.output('blob');

      // Upload the PDF to Firebase Storage
      const storageRef = ref(storage, `signed_agreements/${agreementId}.pdf`);
      await uploadBytes(storageRef, pdfBlob);

      // Get the download URL of the uploaded PDF
      const downloadUrl = await getDownloadURL(storageRef);

      // Update the Firestore document with the signed URL
      const agreementDoc = doc(db, 'agreements', agreementId);
      await updateDoc(agreementDoc, {
        status: 'Signed',
        signedUrl: downloadUrl,
      });

      // Navigate to the download page
      navigate(`/download-signed-agreement/${agreementId}`);
    } else {
      setError('Signature data or agreement reference is missing');
    }
  } catch (error) {
    setError(`Error signing document: ${error.message}`);
  } finally {
    setLoading(false);
  }
};


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      {isAuthenticated && <NavigationBar />}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/3 p-5 overflow-auto">
          <SigningInstructions />
          {agreement ? (
            <div
              ref={agreementRef}
              className="agreement-content relative"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}
            >
              <div className="text-gray-700 dark:text-gray-200 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: agreement.text }} />
              {freelancerSignatures.map((signatureData, index) => (
                <img
                  key={index}
                  ref={signatureRef}
                  src={signatureData}
                  alt="Signature"
                  className="absolute cursor-move"
                  style={{ left: signaturePosition.x, top: signaturePosition.y, width: `${signatureSize.width}px`, height: `${signatureSize.height}px` }}
                />
              ))}
              {clientSignatures.map((signatureData, index) => (
                <img
                  key={index}
                  ref={signatureRef}
                  src={signatureData}
                  alt="Signature"
                  className="absolute cursor-move"
                  style={{ left: signaturePosition.x, top: signaturePosition.y, width: `${signatureSize.width}px`, height: `${signatureSize.height}px` }}
                />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">No agreement available. Please wait...</p>
            </div>
          )}
        </div>
        <div className="sticky top-0 w-full md:w-1/3 p-5 border-b md:border-b-0 md:border-l bg-white dark:bg-gray-800">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Sign as:</label>
            <select
              value={signingAs}
              onChange={(e) => setSigningAs(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
            >
              <option value="freelancer">Freelancer</option>
              <option value="client">Client</option>
            </select>
          </div>
          {showSignFlow ? (
            <>
              <button className="bg-white dark:bg-gray-800 text-black dark:text-white p-2 mb-4 rounded-lg flex items-center justify-center hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300" onClick={toggleSignFlow}>
                <IoMdClose size={24} className="mr-2" /> Hide
              </button>
              <div
                ref={containerRef}
                className="border border-gray-300 dark:border-gray-600 rounded-lg p-2 relative"
                style={{ height: '300px' }}
              >
                <div className="h-full">
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{
                      className: 'sigCanvas w-full h-full'
                    }}
                    onEnd={handleSignatureEnd}
                  />
                </div>
                <div className="absolute bottom-0 left-0 w-full border-t border-dashed border-gray-400 dark:border-gray-600">
                  <span className="bg-white dark:bg-gray-800 px-2 text-xs text-gray-400 dark:text-gray-300">Sign Here</span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 mt-4">
                <button className="text-black dark:text-white p-2 rounded-lg flex items-center justify-center hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-300" onClick={clearSignature}>
                  <IoMdRefresh size={24} className="mr-2" /> Reset
                </button>
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Signature Width:</label>
                <input
                  type="number"
                  name="width"
                  value={signatureSize.width}
                  onChange={handleResize}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
                />
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-2">Signature Height:</label>
                <input
                  type="number"
                  name="height"
                  value={signatureSize.height}
                  onChange={handleResize}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
                />
              </div>
              <div className="mt-4">
                <button className="bg-green-500 text-white p-2 rounded-lg flex items-center justify-center hover:bg-green-700 transition duration-300 w-full" onClick={signDocument}>
                  <IoMdCheckmarkCircleOutline size={24} className="mr-2" /> Sign
                </button>
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-full">
              <button
                className="bg-green-500 text-white p-2 rounded-lg flex items-center justify-center hover:bg-green-700 transition duration-300"
                onClick={toggleSignFlow}
              >
                <IoMdCheckmarkCircleOutline size={24} className="mr-2" /> Sign Document
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignaturePage;